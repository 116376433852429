import loginRoutes from "Components/Templates/Login/login.routes";
import { RouterProvider, redirect } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import themeRoutes from "theme/app/routes";
import "./theme/styles/app/app.scss";
import { GlobalFonts } from "App.style";
import ForgotPasswordRoutes from "Components/Templates/ForgotPassword/ForgotPassword.routes";
import { withUserContext } from "./shared/ContextProviders/UserContextProvider/UserContextProvider";

import ToastContainer from "Components/Molecules/ToastContainer/ToastContainer";
import { MantineProvider, createTheme } from "@mantine/core";
// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/carousel/styles.css";
import "react-international-phone/style.css";
import ResetPasswordRoutes from "Components/Templates/ResetPassword/ResetPassword.routes";

const router = createBrowserRouter([
  ...loginRoutes,
  ...ForgotPasswordRoutes,
  ...ResetPasswordRoutes,
  ...themeRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/login"),
  },
]);

const theme = createTheme({
  defaultRadius: "xl",
  fontFamily: "Public Sans, sans-serif",
});
function App() {
  return (
    <>
      <MantineProvider theme={theme}>
        <GlobalFonts />
        <RouterProvider router={router} />
        <ToastContainer />
      </MantineProvider>
    </>
  );
}

export default withUserContext(App);
