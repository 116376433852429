import {
  Container,
  ScrollArea,
  ActionIcon,
  Title,
  Button,
  Flex,
  Modal,
  Tooltip,
  TextInput,
  NumberInput,
  MultiSelect,
  Box,
  Switch,
  Loader,
} from "@mantine/core";
import moment from "moment";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  MembershipsTabContextProvider,
  useMembershipsTabContext,
} from "./MembershipsTab.context";
import { useMemo, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";
import { useCenterContext } from "../../Center.context";
import { ALL_SERVICE_NAMES } from "shared/Constants/general.const";

const MembershipsTabContainer = () => {
  const {
    handleSave,
    membersData,
    boatsData,
    membershipDetailsEdit,
    setHarborId,
    servicesIds,
    loading,
  } = useMembershipsTabContext();
  const { harbourData, setIsButtonDisabled } = useCenterContext();
  const [opened, { open, close }] = useDisclosure(false);
  const [validationErrors, setValidationErrors] = useState({});
  const Table_Data = membersData;
  const Boats_Data = boatsData;
  const [isAddNewButtonDisabled, setAddNewButtonDisabled] = useState(false);

  const [selectedServiceCategoryList, setSelectedServiceCategoryList] =
    useState({
      Captain: false,
      Cleaning: false,
      Catering: false,
      Docking: false,
      Refueling: false,
      "Water Toys": false,
      "Extra Order": false,
    });

  const [selectedServiceCategoryListIds, setSelectedServiceCategoryListIds] =
    useState([]);
  useEffect(() => {
    setHarborId(harbourData?.harborId);
  }, [harbourData]);
  const disableAddNewButton = (isDisabled) => {
    setAddNewButtonDisabled(isDisabled);
  };
  const mappedBoatsData =
    Boats_Data &&
    Boats_Data?.map((boat) => {
      return {
        value: boat?.id,
        label: boat?.boatName,
      };
    });

  const isMembershipDataEmpty =
    !harbourData?.harborId || harbourData?.harborId.length === 0;

  const handleServiceCategoryChange = (event, serviceCategoryName) => {
    const preSelectedServices = selectedServiceCategoryList;
    const preSelectedServiceCategoryListIds = [];
    preSelectedServices[serviceCategoryName] = event.currentTarget.checked;
    servicesIds.forEach((category) => {
      if (preSelectedServices[category.serviceCategoryName]) {
        preSelectedServiceCategoryListIds.push(category.serviceCategoryId);
      }
    });

    setSelectedServiceCategoryListIds(preSelectedServiceCategoryListIds);
  };
  const [title, setTitle] = useState("");
  const [membershipFee, setMembershipFee] = useState(0);
  const [insuranceFee, setInsuranceFee] = useState(0);
  const [boatsCategories, setBoatsCategories] = useState([]);
  const [boatDays, setBoatDays] = useState(0);
  const [selectedBoatsIds, setSelectedBoatsIds] = useState([]);
  const [editServiceCategories, setEditServiceCategories] = useState([]);
  const [initial, setInitial] = useState(false);
  const { selectedClub } = useClubsContext();

  const handleBoatsIdsChangeEdit = (selectedValues) => {
    setSelectedBoatsIds(selectedValues);
    setInitial(true);
  };

  const handleCheckChange = (event, categoryId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      if (!editServiceCategories.includes(categoryId)) {
        setEditServiceCategories((prevCategories) => [
          ...prevCategories,
          categoryId,
        ]);
      }
    } else {
      if (editServiceCategories.includes(categoryId)) {
        setEditServiceCategories((prevCategories) =>
          prevCategories.filter((id) => id !== categoryId)
        );
      }
    }
  };

  const serviceIDArray = servicesIds.map(
    ({ serviceCategoryId, serviceCategoryName }) => ({
      [serviceCategoryName]: serviceCategoryId,
    })
  );
  const columns = useMemo(
    () => [
      {
        accessorKey: "createdAt",
        header: "Date Added",
        enableEditing: false,
        size: 120,
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => moment(row.original.startDate).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "levelName",
        header: "Title",
        size: 100,
        mantineTableBodyCellProps: {
          align: "center",
        },

        mantineEditTextInputProps: {
          error: validationErrors.levelName,
          required: true,

          onChange: (event) => {
            const value = event.target.value;

            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                levelName: "Title Name is required",
              }));
            } else {
              delete validationErrors.levelName;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },

      {
        accessorKey: "membershipFee",
        header: "Membership Fee",
        size: 120,
        mantineTableBodyCellProps: {
          align: "center",
        },

        mantineEditTextInputProps: {
          error: validationErrors.membershipFee,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                membershipFee: "Membership Fee is required",
              }));
            } else if (value < 1) {
              setValidationErrors({
                ...validationErrors,
                membershipFee: "Negative values are not allowed.",
              });
            } else {
              delete validationErrors.membershipFee;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "insuranceFee",
        header: "Insurance Fee",
        mantineTableBodyCellProps: {
          align: "center",
        },

        mantineEditTextInputProps: {
          error: validationErrors.insuranceFee,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                insuranceFee: "Insurance Fee is required",
              }));
            } else if (value < 0) {
              setValidationErrors({
                ...validationErrors,
                insuranceFee: "Negative values are not allowed.",
              });
            } else {
              delete validationErrors.insuranceFee;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "boatsCategories",
        header: "Boats Categories",
        enableEditing: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div>{row.original.boatsCategories?.join(", ")}</div>
        ),
      },

      {
        accessorKey: "boatsIds",
        header: "Boats Names",
        Edit: ({ row, column, table }) => {
          !initial && setSelectedBoatsIds(row.original.boatsIds);
          return (
            <>
              <MultiSelect
                placeholder="Boats Names"
                value={selectedBoatsIds}
                data={mappedBoatsData}
                hidePickedOptions
                onChange={handleBoatsIdsChangeEdit}
                error={selectedBoatsIds.length <= 0}
              />
              {selectedBoatsIds.length <= 0 && (
                <p style={{ color: "red" }}>Boats are required</p>
              )}
            </>
          );
        },
        mantineTableBodyCellProps: {
          align: "center",
        },

        Cell: ({ row }) => <div>{row.original.boatsNames?.join(", ")}</div>,
      },
      {
        accessorKey: "boatingDays",
        header: "Boating Days",
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.boatingDays,
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                boatingDays: "Boating Days is required",
              }));
            } else if (value < 1) {
              setValidationErrors({
                ...validationErrors,
                boatingDays: "Negative values are not allowed.",
              });
            } else {
              delete validationErrors.boatingDays;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "waterToys",
        header: "Water Toys",
        size: 120,
        mantineTableBodyCellProps: {
          align: "center",
        },

        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Water Toys"
          );

          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Water Toys"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Water Toys"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Water Toys"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Water Toys"])
              }
            />
          );
        },
      },

      {
        accessorKey: "catering",
        header: "Catering",
        editVariant: "select",
        size: 120,

        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Catering"
          );
          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Catering"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Catering"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Catering"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Catering"])
              }
            />
          );
        },
      },
      {
        accessorKey: "captain",
        header: "Captain",
        editVariant: "select",
        size: 120,

        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Captain"
          );
          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Captain"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Captain"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Captain"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Captain"])
              }
            />
          );
        },
      },
      {
        accessorKey: "cleaning",
        header: "Cleaning",
        editVariant: "select",
        size: 120,

        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Cleaning"
          );
          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Cleaning"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Cleaning"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Cleaning"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Cleaning"])
              }
            />
          );
        },
      },
      {
        accessorKey: "refueling",
        header: "Refueling",
        editVariant: "select",
        size: 120,

        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Refueling"
          );
          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Refueling"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Refueling"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Refueling"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Refueling"])
              }
            />
          );
        },
      },
      {
        accessorKey: "otherServices",
        header: "Other Services",
        editVariant: "select",
        size: 120,
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Other Services"
          );
          return (
            <Switch
              checked={row.original.serviceCategories.includes(
                categoryId["Other Services"]
              )}
            />
          );
        },
        Edit: ({ row }) => {
          const categoryId = serviceIDArray.find(
            (obj) => Object.keys(obj)[0] === "Other Services"
          );
          return (
            <Switch
              defaultChecked={row.original.serviceCategories.includes(
                categoryId["Other Services"]
              )}
              onChange={(event) =>
                handleCheckChange(event, categoryId["Other Services"])
              }
            />
          );
        },
      },
    ],
    [validationErrors, mappedBoatsData]
  );

  const [tableData, setTableData] = useState(() => Table_Data);

  const handleSaveRow = async ({ table, row, values }) => {
    setInitial(false);
    if (Object.keys(validationErrors).length || selectedBoatsIds <= 0) {
      toast.error("Please enter valid fields and try saving again!");
    } else {
      setIsButtonDisabled(false);
      disableAddNewButton(false);
      const membershipLevelId = row.original.membershipLevelId;
      const newMembershipDetails = {
        // membershipLevelId: row.original.membershipLevelId,
        clubId: row.original.clubId,
        harborId: harbourData?.harborId,
        levelName: values.levelName,
        membershipFee: Number(values.membershipFee),
        insuranceFee: Number(values.insuranceFee),
        boatsIds: selectedBoatsIds,
        serviceCategoryIds: editServiceCategories,
        // boatsNames: row.original.boatsNames,
        boatsCategories: row.original.boatsCategories,
        boatingDays: Number(values.boatingDays),
        createdAt: row.original.createdAt,
      };

      membershipDetailsEdit(newMembershipDetails, membershipLevelId);

      tableData[row.index] = values;
      setTableData([...tableData]);
      table.setEditingRow(null);
    }
  };

  const form = useForm({
    initialValues: {
      title: "",
      membership: "",
      insurance: "",
      boats: [],
      boatingDays: "",
    },

    validate: {
      title: (value) => (value.length < 1 ? "Title must reqired." : null),

      membership: (value) =>
        value < 1 ? "Membership Fee must be a above 0." : null,

      insurance: (value) => (value < 0 ? "Insurance Fee required." : null),

      boatingDays: (value) =>
        value < 1 ? " Boating Days must be a number above 1." : null,
      boats: isNotEmpty("Boats must contain atleast one value"),
    },
  });

  const resetStateValues = () => {
    form.reset({
      title: "",
      membership: "",
      insurance: "",
      boats: "",
      boatingDays: "",
    });
    setBoatsCategories([]);
    setSelectedServiceCategoryList({
      Captain: false,
      Cleaning: false,
      Catering: false,
      Docking: false,
      Refueling: false,
      "Water Toys": false,
      "Extra Order": false,
    });
  };

  const postData = {
    clubId: selectedClub?.clubId,
    harborId: harbourData?.harborId,
    levelName: form.values.title,
    membershipFee: form.values.membership,
    insuranceFee: form.values.insurance,
    boatingDays: form.values.boatingDays,
    boatsCategories: boatsCategories,
    boatsIds: form.values.boats,
    serviceCategoryIds: selectedServiceCategoryListIds,
  };

  //EDIT action
  const handleEditClick = (row, table) => {
    setIsButtonDisabled(true);
    disableAddNewButton(true);
    setEditServiceCategories(row.original.serviceCategories);
    table.setEditingRow(row);
  };
  const table = useMantineReactTable({
    columns,
    enablePagination: true,
    enableSorting: true,
    enableFullScreenToggle: false,
    enableTopToolbar: true,
    data: membersData,
    onEditingRowSave: handleSaveRow,
    editDisplayMode: "row",
    enableEditing: true,
    getRowId: (row) => row.membershipLevelId,
    onEditingRowCancel: () => {
      setInitial(false);
      setValidationErrors({});
      setIsButtonDisabled(false);
      disableAddNewButton(false);
    },

    renderRowActions: ({ row, table }) => (
      <Flex gap="md" justify="center">
        <Tooltip label="Edit">
          <ActionIcon
            color="#ffffff"
            onClick={() => handleEditClick(row, table)}
          >
            <IconEdit
              height={28}
              width={28}
              color="black"
              style={{ border: "1px solid #d0d6dc", borderRadius: "12px" }}
            />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <ScrollArea>
      <Container fluid>
        <Flex direction="row" align="center">
          <Title order={3} ml={-16}>
            Memberships- {harbourData && harbourData.harborName}
          </Title>
          <Modal
            opened={opened}
            onClose={() => {
              close();
              resetStateValues();
            }}
            title={<span style={{ fontWeight: "bold" }}>Add Details</span>}
            centered
            overlayProps={{ backgroundOpacity: 0.55, blur: 3 }}
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            <Box
              component="form"
              onSubmit={form.onSubmit(() => {
                if (form.isValid()) {
                  handleSave(postData);
                  close();
                  resetStateValues();
                }
              })}
            >
              <TextInput
                label={<span style={{ fontWeight: "bold" }}>Title</span>}
                withAsterisk
                placeholder="Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  form.change("title", e.target.value);
                }}
                {...form.getInputProps("title")}
              />
              <NumberInput
                label={
                  <span style={{ fontWeight: "bold" }}>Membership Fee</span>
                }
                placeholder="Membership Fee"
                min={1}
                withAsterisk
                value={membershipFee}
                onChange={(value) => {
                  setMembershipFee(value);
                }}
                {...form.getInputProps("membership")}
              />
              <NumberInput
                label={
                  <span style={{ fontWeight: "bold" }}>Insurance Fee</span>
                }
                placeholder="Insurance Fee"
                min={0}
                withAsterisk
                value={insuranceFee}
                onChange={(value) => {
                  setInsuranceFee(value);
                }}
                {...form.getInputProps("insurance")}
              />
              <MultiSelect
                label={<span style={{ fontWeight: "bold" }}>Boats</span>}
                placeholder="Boats"
                data={mappedBoatsData}
                clearable
                hidePickedOptions
                withAsterisk
                {...form.getInputProps("boats")}
              />
              <NumberInput
                label={<span style={{ fontWeight: "bold" }}>Boating Days</span>}
                placeholder="Boating days"
                min={1}
                value={boatDays}
                withAsterisk
                onChange={(value) => {
                  setBoatDays(value);
                }}
                {...form.getInputProps("boatingDays")}
              />
              <Container fluid mt={12}>
                <Flex justify={"space-between"}>
                  <Flex direction="column">
                    <Switch
                      label={ALL_SERVICE_NAMES.WATER_TOYS}
                      checked={
                        selectedServiceCategoryList[
                          ALL_SERVICE_NAMES.WATER_TOYS
                        ]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.WATER_TOYS
                        );
                      }}
                    />
                    <Switch
                      mt={4}
                      label={ALL_SERVICE_NAMES.CATERING}
                      checked={
                        selectedServiceCategoryList[ALL_SERVICE_NAMES.CATERING]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.CATERING
                        );
                      }}
                    />
                    <Switch
                      mt={4}
                      label={ALL_SERVICE_NAMES.CAPTAIN}
                      checked={
                        selectedServiceCategoryList[ALL_SERVICE_NAMES.CAPTAIN]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.CAPTAIN
                        );
                      }}
                    />
                  </Flex>
                  <Flex direction="column" mt={4}>
                    <Switch
                      label={ALL_SERVICE_NAMES.CLEANING}
                      checked={
                        selectedServiceCategoryList[ALL_SERVICE_NAMES.CLEANING]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.CLEANING
                        );
                      }}
                    />
                    <Switch
                      mt={4}
                      label={ALL_SERVICE_NAMES.REFUELING}
                      checked={
                        selectedServiceCategoryList[ALL_SERVICE_NAMES.REFUELING]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.REFUELING
                        );
                      }}
                    />
                    <Switch
                      mt={4}
                      label={ALL_SERVICE_NAMES.OTHER_SERVICES}
                      checked={
                        selectedServiceCategoryList[
                          ALL_SERVICE_NAMES.OTHER_SERVICES
                        ]
                      }
                      onChange={(e) => {
                        handleServiceCategoryChange(
                          e,
                          ALL_SERVICE_NAMES.OTHER_SERVICES
                        );
                      }}
                    />
                  </Flex>
                </Flex>
              </Container>
              <Flex justify="space-between" mt={12}>
                <Button
                  size="md"
                  onClick={() => {
                    close();
                    resetStateValues();
                  }}
                >
                  Cancel
                </Button>
                <Button size="md" type="submit">
                  Save
                </Button>
              </Flex>
            </Box>
          </Modal>
          <Button
            onClick={open}
            leftSection={<IconPlus size={14} />}
            variant="default"
            m={8}
            disabled={isAddNewButtonDisabled || isMembershipDataEmpty}
          >
            Add New
          </Button>
        </Flex>
      </Container>
      {loading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader size={"xl"} />
        </Flex>
      ) : (
        <MantineReactTable
          table={table}
          radius="sm"
          editDisplayMode="row"
          enableColumnActions={true}
          enableDensityToggle={true}
          enableFullScreenToggle={false}
          enable
          enablePagination={true}
          enableSorting={true}
          enableEditing
          rowKey={(row) => row.membershipLevelId}
        />
      )}
    </ScrollArea>
  );
};

const MembershipsTab = () => {
  return (
    <MembershipsTabContextProvider>
      <MembershipsTabContainer />
    </MembershipsTabContextProvider>
  );
};

export default MembershipsTab;
