import {
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Image,
  NumberInput,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
  Loader,
} from "@mantine/core";
import { IconRestore } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../assets/images/placeholder.png";
import {
  ClubSettingContextProvider,
  useClubSettingContext,
} from "./ClubSetting.context";
import { isEmail, useForm } from "@mantine/form";
import { CountryList, Vat } from "shared/Constants/general.const";
import toast from "react-hot-toast";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import { PhoneInput } from "react-international-phone";

const ClubSettingContainer = () => {
  const { clubDetails, editClubDetails, uploadImage, loading, setLoading } =
    useClubSettingContext();
  const { userDetails } = useUserContext();

  const clubDetailsList = clubDetails[0];
  const form = useForm({
    initialValues: {
      clubName: clubDetailsList?.clubName || "",
      clubLogo: clubDetailsList?.clubLogo || "",
      clubFirstName: clubDetailsList?.clubContactFirstName || "",
      clubLastName: clubDetailsList?.clubContactLastName || "",
      clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
      clubZipCode: clubDetailsList?.clubPostcode || "",
      clubCity: clubDetailsList?.clubCity || "",
      clubCountry: clubDetailsList?.clubCountry || "",
      clubPhone: clubDetailsList?.clubPhone || "",
      clubEmail: clubDetailsList?.clubEmail || "",
      clubVat: clubDetailsList?.clubVAT || "",
      clubManagerId: clubDetailsList?.clubManagerId || "",
    },
    validate: {
      clubName: (value) => (value ? null : "Please enter club name"),

      clubFirstName: (value) => (value ? null : "Please enter club first name"),
      clubLastName: (value) => (value ? null : "Please enter club last name"),
      clubStreetAddress: (value) =>
        value ? null : "Please enter club street address",
      clubZipCode: (value) => (value ? null : "Please enter club zip code"),
      clubCity: (value) => (value ? null : "Please enter club city"),
      clubCountry: (value) => (value ? null : "Please enter club country"),
      clubPhone: (value) => (value ? null : "Please enter club phone"),
      clubEmail: isEmail("Please enter club email"),
      clubVat: (value) => (value ? null : "Please enter club vat"),
    },
  });

  useEffect(() => {
    if (clubDetailsList) {
      form.setValues({
        clubName: clubDetailsList?.clubName || "",
        clubLogo: clubDetailsList?.clubLogo || "",
        clubFirstName: clubDetailsList?.clubContactFirstName || "",
        clubLastName: clubDetailsList?.clubContactLastName || "",
        clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
        clubZipCode: clubDetailsList?.clubPostcode || "",
        clubCity: clubDetailsList?.clubCity || "",
        clubCountry: clubDetailsList?.clubCountry || null,
        clubPhone: clubDetailsList?.clubPhone || "",
        clubEmail: clubDetailsList?.clubEmail || "",
        clubVat: clubDetailsList?.clubVAT || "",
        clubManagerId: clubDetailsList?.clubManagerId || "",
      });
    }
  }, [clubDetailsList]);
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState(null);

  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const handleCancelClick = () => {
    form.setValues({
      clubName: clubDetailsList?.clubName || "",
      clubLogo: clubDetailsList?.clubLogo || "",
      clubFirstName: clubDetailsList?.clubContactFirstName || "",
      clubLastName: clubDetailsList?.clubContactLastName || "",
      clubStreetAddress: clubDetailsList?.clubStreetAddress || "",
      clubZipCode: clubDetailsList?.clubPostcode || "",
      clubCity: clubDetailsList?.clubCity || "",
      clubCountry: clubDetailsList?.clubCountry || "",
      clubPhone: clubDetailsList?.clubPhone || "",
      clubEmail: clubDetailsList?.clubEmail || "",
      clubVat: clubDetailsList?.clubVAT || "",
    });
    setIsEditing(false);
    resetImageState();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const saveClubDetails = () => {
    setLoading(true);
    const clubId = clubDetailsList?.id;
    const newClubDetails = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: form.values.clubManagerId,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
    };

    if (file) {
      uploadImage(file, clubId)
        .then((res) => {
          const newLogo = res?.uploadedFileUrls[0];
          if (newLogo) {
            newClubDetails.clubLogo = newLogo;
          } else {
            newClubDetails.clubLogo = form.values.clubLogo;
          }

          editClubDetails(newClubDetails, clubId)
            .then(() => {
              toast("Save successful!", { appearance: "success" });
              setIsEditing(false);

              // form.reset();
            })
            .catch((error) => {
              toast(error.message || "Save failed. Please try again.", {
                appearance: "error",
              });
            });
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
        });
    } else {
      editClubDetails(newClubDetails, clubId)
        .then(() => {
          toast("Save successful!", { appearance: "success" });
          setIsEditing(false);
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
        });
    }
  };

  const resetImageState = () => {
    setFile(null);
    setFileUrl("");
  };

  return (
    <Flex direction="column" gap="md" px={"md"}>
      {loading ? (
        <Flex justify={"center"} h={"70vh"} align={"center"}>
          <Loader size={50} />
        </Flex>
      ) : (
        <Box
          component="form"
          onSubmit={form.onSubmit((values) => {
            if (form.isValid()) {
              saveClubDetails();
            }
          })}
        >
          <Flex align={"center"} justify={"space-between"} mb="md">
            <Title order={3}>Club Details</Title>
            {isEditing ? (
              <Flex mt="md" justify="space-between" w={"13%"}>
                <Button
                  size="sm"
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
                <Button size="sm" type="submit">
                  Save
                </Button>
              </Flex>
            ) : (
              <Flex mt="md">
                <Button
                  size="sm"
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Edit
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex w={"100%"} columnGap={"lg"}>
            <Flex direction="column" w={"50%"}>
              <TextInput
                withAsterisk
                readOnly={!isEditing}
                label={<span style={{ fontWeight: "bold" }}>Club Name</span>}
                placeholder="Club Name"
                radius="xs"
                {...form.getInputProps("clubName")}
              />
              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>Club Manager Name</span>
                }
                radius="xs"
                placeholder="Club Manager Name"
                readOnly
                value={userDetails?.firstName + " " + userDetails?.lastName}
              />
              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Club Contact First Name
                  </span>
                }
                radius="xs"
                placeholder="Club Contact First Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubFirstName")}
              />
              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Club Contact Last Name
                  </span>
                }
                radius="xs"
                placeholder="Club Last Name"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubLastName")}
              />
              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Club Street Address
                  </span>
                }
                radius="xs"
                placeholder="Club Street Address"
                withAsterisk
                readOnly={!isEditing}
                {...form.getInputProps("clubStreetAddress")}
              />
              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>Club PostCode</span>
                }
                radius="xs"
                withAsterisk
                placeholder="Club PostCode"
                readOnly={!isEditing}
                {...form.getInputProps("clubZipCode")}
              />
              <TextInput
                label={<span style={{ fontWeight: "bold" }}>Club City</span>}
                withAsterisk
                radius="xs"
                placeholder="Club City"
                readOnly={!isEditing}
                {...form.getInputProps("clubCity")}
              />
            </Flex>
            <Flex direction="column" w={"50%"}>
              <>
                <Text mt={4} fw="bold">
                  Club Logo
                </Text>
                <Flex justify="center">
                  {fileUrl ? (
                    <Image
                      radius="md"
                      fit="contain"
                      w={100}
                      h={100}
                      src={fileUrl || placeholder}
                    />
                  ) : (
                    <Image
                      radius="md"
                      fit="contain"
                      w={120}
                      h={120}
                      src={form.values.clubLogo || placeholder}
                    />
                  )}
                </Flex>
                <Group justify="center">
                  <FileButton onChange={setFile} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button {...props} color="gray" disabled={!isEditing}>
                        Upload Logo
                      </Button>
                    )}
                  </FileButton>
                  <Button
                    variant="default"
                    ml={8}
                    onClick={resetImageState}
                    disabled={!isEditing}
                  >
                    <Tooltip label="Reset Image">
                      <IconRestore />
                    </Tooltip>
                  </Button>
                </Group>
              </>

              <Select
                label={<span style={{ fontWeight: "bold" }}>Club Country</span>}
                withAsterisk
                placeholder="Club Country"
                searchable
                radius="xs"
                clearable
                readOnly={!isEditing}
                data={CountryList}
                {...form.getInputProps("clubCountry")}
              />

              <Text style={{ fontSize: "14.5px", fontWeight: "500" }}>
                Phone
                <span style={{ color: "red" }}> *</span>
              </Text>
              <style>
                {`
                 .react-international-phone-input {
                    width: 600px;
  }
`}
              </style>
              <PhoneInput
                defaultCountry="de"
                {...form.getInputProps("clubPhone")}
                disabled={!isEditing}
              />
              <TextInput
                label={<span style={{ fontWeight: "bold" }}>Club Email</span>}
                withAsterisk
                radius="xs"
                placeholder="Club Email"
                readOnly={!isEditing}
                {...form.getInputProps("clubEmail")}
              />

              <TextInput
                label={
                  <span style={{ fontWeight: "bold" }}>Club VAT Number</span>
                }
                withAsterisk
                radius="xs"
                placeholder="Club VAT Number"
                readOnly={!isEditing}
                {...form.getInputProps("clubVat")}
              />
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

const ClubSetting = () => {
  return (
    <ClubSettingContextProvider>
      <ClubSettingContainer />
    </ClubSettingContextProvider>
  );
};
export default ClubSetting;
